import { useOperationalClass, useProcessClasses } from "@utils/hooks"
import store from "../../store"
import _ from "lodash"
import { useSelector } from "react-redux"
import { userSelector } from "@redux/user"
import { EVENT_TYPE_COLORS, TEST_CATEGORIES_FLOORBALL_PLAYER, TEST_CATEGORIES_FOOTBALL_PLAYER } from "@/const"
import { useGetRootGroupQuery } from "@api/group-api"
import { useGetTemplateRolesQuery } from "@api/templaterole-api"
import { useGetEventTypesQuery } from "@api/eventType-api"
import { loc } from "@utils/localization"

export const SPORT_COACH_ROLE_NAME = "coach"
export const SPORT_PLAYER_ROLE_NAME = "player"
export const WELLBEING_OPERATIONAL_NAME = "hyvinvointi"
export const WELLBEING_PROCESS_NAME = "k-hyvinvointi-pilotti"
export const WELLBEING_COACH_ROLE_NAME = "hyvinvointi_valmentaja"
export const WELLBEING_PARTICIPANT_ROLE_NAME = "hyvinvointi_osallistuja"

export const EVENT_PATHS = {
	[WELLBEING_OPERATIONAL_NAME]: "/wb/events/",
	football: "/events/",
	floorball: "/events/",
	futsal: "/events/"
}

/**
 * Returns the name of the operational class.
 * 
 * NOTE: Due to not being a hook with API query, will not function for
 * admin users with that have selected a root group context in the role selector.
 * 
 * @returns {String} The name of the operational class.
 */
export const getOperationalClassName = () => {
	const state = store.getState()
	const profileDataForCtx = _.get(state, "user.profileDataForCtx")
	if (!profileDataForCtx) {
		return null
	}
	const opClass = profileDataForCtx.operationalClass
	const { name } = opClass || {}
	return name
}

export const useOperationalClassName = () => {
	const { profileDataForCtx } = useSelector(userSelector)
	if (!profileDataForCtx) {
		return null
	}
	const opClass = profileDataForCtx.operationalClass
	const { name } = opClass || {}
	return name
}

/**
 * Gets bucket level for FFA club support as defined in root group's feature configuration.
 * 
 * Uses product levels identifier names to get this information ("kori1", "kori2", etc.)
 * 
 * Level 1 means access to assign both academy and talent players.
 * Level 2 means access to assign only talent players.
 * Level 3 means no access to either.
 * 
 * @returns {1 | 2 | 3 | null} Either 1 or 2 depending on level, or null if neither feature is used for current root group.
 */
export const getFFAClubSupportBucketLevel = () => {
	const BUCKET1 = "kori1"
	const BUCKET2 = "kori2"
	const BUCKET3 = "kori3"

	/** @type {1 | 2 | null} */
	let level = null

	const productLevels = getProductLevels()
	if (productLevels?.find((level) => level.name === BUCKET1)) level = 1
	if (productLevels?.find((level) => level.name === BUCKET2)) level = 2
	if (productLevels?.find((level) => level.name === BUCKET3)) level = 3

	return level
}

/** 
 * Variant of `getFFAClubSupportBucketLevel` that utilizes a query. This fixes issues with synchronization for context switching. 
 * 
 * Important to note; because this is an asynchronous hook, "undefined" is returned when data is not yet received. "null" means data was received but bucket level not found.
 * */
export const useGetFFAClubSupportBucketLevelByRootGroup = (rootGroupId) => {
	const BUCKET1 = "kori1"
	const BUCKET2 = "kori2"
	const BUCKET3 = "kori3"

	/** @type {1 | 2 | 3 | null} */
	let level = null

	const { data: rootGroup } = useGetRootGroupQuery({ id: rootGroupId }, { refetchOnMountOrArgChange: true })
	if (!rootGroup) {
		return undefined
	}

	if (rootGroup?.classes?.find((level) => level.name === BUCKET1)) level = 1
	if (rootGroup?.classes?.find((level) => level.name === BUCKET2)) level = 2
	if (rootGroup?.classes?.find((level) => level.name === BUCKET3)) level = 3

	return level
}

/**
 * Returns whether user has access to Finnish Football Association's club support.
 * 
 * This hook is a "broad" check, don't use this for checking if user can assign players, which is only available for bucket levels 1 and 2.
 * 
 * Conditions are as follows:
 * 	1. Is a head coach (admins with head coach context are also allowed)
 *  2. Has a non-null bucket level (1, 2, 3)
 */
export const useHasAccessToFFAClubSupport = () => {
	const { contextData, isPlainAdmin, isAdminWithCtx, hasClubSupportAdmin } = useSelector(userSelector)
	const isHeadCoach = contextData?.groupRole === "headcoach"
	const bucketLevel = useGetFFAClubSupportBucketLevelByRootGroup(contextData?.rootGroup)

	if (isPlainAdmin || isAdminWithCtx || hasClubSupportAdmin) {
		return true
	}

	return !!bucketLevel && isHeadCoach
}

/**
 * Returns whether or not user is a plain admin.
 * @returns {boolean} Is user plain admin
 */
export const getIsPlainAdmin = () => {
	const state = store.getState()
	const isAdmin = _.get(state, "user.selectedRole") === "admin"
	const hasRootGroupInContext = _.get(state, "user.contextData.rootGroup")
	return isAdmin && !hasRootGroupInContext
}

/**
 * Returns whether or not user is admin with a rootgroup context enabled
 */
export const getIsAdminWithContext = () => {
	const state = store.getState()
	const isAdmin = _.get(state, "user.selectedRole") === "admin"
	const hasRootGroupInContext = _.get(state, "user.contextData.rootGroup")
	return isAdmin && hasRootGroupInContext
}


export const useIsAdminWithContext = () => {
	const { selectedRole, contextData } = useSelector(userSelector)
	const isAdmin = selectedRole === "admin"
	const hasRootGroupInContext = contextData.rootGroup
	return isAdmin && hasRootGroupInContext
}

export const getIsPlayerOrCoach = () => {
	const contextData = getContextData()
	return [ "coach", "headcoach", "player"].includes(contextData?.groupRole)
}

/**
 * Returns whether user is a coach or headcoach.
 */
export const getIsCoach = () => {
	const state = store.getState()
	const contextRole = _.get(state, "user.contextData.groupRole")
	return ["coach", "headcoach"].includes(contextRole)
}

export const useIsCoach = () => {
	const { contextData } = useSelector(userSelector)
	const contextRole = contextData.groupRole
	return  ["coach", "headcoach"].includes(contextRole)
}

/**
 * Returns to process class where parent is operational class.
 * Process class parents must include operational class _id.
 * @returns {Object} The process class.
 */
export const useGetProcessClass = () => {
	const op = useOperationalClass()
	if (!op) {
		return null
	}
	const { profileDataForCtx } = useSelector(userSelector)
	return profileDataForCtx?.processClasses?.find(p => p.parents.includes(op._id))
}

/** Returns `contextData` object without utilizing hooks. */
export const getContextData = () => {
	const state = store.getState()
	return _.get(state, "user.contextData") ?? {}
}

/**
 * Returns all page customizations linked to current operational class.
 * @returns {object[]}
 */
export const useGetPageCustomizations = () => {
	const opClass = useOperationalClass()
	const pageCustomizations = _.get(opClass, "pageCustomizations")
	return pageCustomizations
}

/**
 * Returns one page customization by section name. If cannot be found, returns undefined.
 * @param {string} section - Section name (e.g. *physicalDevelopmentGraphs*)
 * @returns {object}
 */
export const useGetPageCustomizationBySection = (section) => {
	const pageCustomizations = useGetPageCustomizations()
	return pageCustomizations.find((o) => o.section === section)
}

/**
 * Returns the names of the process classes.
 * @returns {String} The names of the process classes.
 */
export const getProcessNames = () => {
	const state = store.getState()
	const profileDataForCtx = _.get(state, "user.profileDataForCtx")
	if (!profileDataForCtx) {
		return []
	}
	return profileDataForCtx.processClasses.map(p => p.name)
}

/**
 * Returns the features.
 * @returns {Array[String]} The features.
 */
export const getFeatures = () => {
	const state = store.getState()
	const profileDataForCtx = _.get(state, "user.profileDataForCtx")
	if (!profileDataForCtx) {
		return []
	}
	return profileDataForCtx?.features ?? []
}

export const useFeatures = () => {
	const { profileDataForCtx } = useSelector(userSelector)
	if (!profileDataForCtx) {
		return []
	}
	return profileDataForCtx?.features ?? []
}

/**
 * Returns the product levels.
 * @returns {Array[String]} The product levels.
 */
export const getProductLevels = () => {
	const state = store.getState()
	const profileDataForCtx = _.get(state, "user.profileDataForCtx")
	if (!profileDataForCtx) {
		return []
	}
	return profileDataForCtx.levelClasses
}

export const useProductLevels = () => {
	const { profileDataForCtx } = useSelector(userSelector)
	if (!profileDataForCtx) {
		return []
	}
	return profileDataForCtx.levelClasses
}

/**
 * Get group role in context.
 * @returns {String}
 */
export const getGroupRole = () => {
	const state = store.getState()
	const contextData = _.get(state, "user.contextData")
	if (!contextData) {
		return null
	}
	return contextData.groupRole
}

/**
 * Returns combined personal and group event types
 * from operational class and process class.
 * 
 * The return value is dependent on the user's current context.
 * 
 * @returns {Object} The event types.
 */
export const useGetCombinedEventTypes = () => {
	let op = useOperationalClass()
	let process = useGetProcessClass()
	
	if (!op) {
		op = { personalEventTypes: [], eventTypes: [] }
	}
	const opPersonalEventTypes = op.personalEventTypes
	const opGroupEventTypes = op.eventTypes

	if (!process) {
		process = { personalEventTypes: [], eventTypes: [] }
	}
	const processPersonalEventTypes = process.personalEventTypes
	const processGroupEventTypes = process.eventTypes
	// Combine op and process event types also mark if event type is from process or op
	const personalEventTypes = opPersonalEventTypes.map(et => ({ ...et, source: "operational" }))
		.concat(processPersonalEventTypes.map(et => ({ ...et, source: "process" })))
		.map(o => loc(o))
	const groupEventTypes = opGroupEventTypes.map(et => ({ ...et, source: "operational" }))
		.concat(processGroupEventTypes.map(et => ({ ...et, source: "process" })))
		.map(o => loc(o))
	
	
	return { personalEventTypes, groupEventTypes }
}

/**
 * Returns combined personal and group event types
 * from operational class and process class.
 * 
 * The return value is dependent on the input root group id.
 * 
 * @returns {Object} The event types.
 */
export const useGetCombinedEventTypesInRoot = (rootGroupId) => {
	let op = useOperationalClass(rootGroupId)
	let processes = useProcessClasses(rootGroupId)

	if (!rootGroupId) {
		return { personalEventTypes: [], groupEventTypes: [] }
	}
	
	if (!op) {
		op = { personalEventTypes: [], eventTypes: [] }
	}
	if (!processes) {
		processes = []
	}

	const opPersonalEventTypes = op.personalEventTypes
	const opGroupEventTypes = op.eventTypes

	const processPersonalEventTypes = processes.reduce((a,b) => a.concat(b.personalEventTypes), []) 
	const processGroupEventTypes = processes.reduce((a,b) => a.concat(b.eventTypes), []) 
	// Combine op and process event types also mark if event type is from process or op
	const personalEventTypes = opPersonalEventTypes.map(et => ({ ...et, source: "operational" }))
		.concat(processPersonalEventTypes.map(et => ({ ...et, source: "process" })))
		.map(o => loc(o))
	const groupEventTypes = opGroupEventTypes.map(et => ({ ...et, source: "operational" }))
		.concat(processGroupEventTypes.map(et => ({ ...et, source: "process" })))
		.map(o => loc(o))
	return { personalEventTypes, groupEventTypes }
}

/**
 * Returns all personal and group event types
 */
export const useGetCombinedEventTypesAll = () => {
	const { data } = useGetEventTypesQuery()
	if (!data) {
		return { personalEventTypes: [], groupEventTypes: [] }
	}
	const personalEventTypes = data.filter(o => o.classes?.some(c => c.name === "personal"))
	const groupEventTypes = data.filter(o => o.classes?.some(c => c.name === "group"))

	return { personalEventTypes, groupEventTypes }
}

export const useEventColors = () => {
	let { personalEventTypes, groupEventTypes } = useGetCombinedEventTypesAll() ?? {}
	const colorDic = (groupEventTypes ?? []).concat(personalEventTypes ?? []).reduce((a,b) => {
		const eventType = b
		const name = eventType.name
		const color = eventType.color
		return { ...a, [name]: color }
	}, {})

	const fallbackDic = EVENT_TYPE_COLORS
	let dic = { ...fallbackDic, ...colorDic }
	return { dic, getColor: type => dic[type] ?? "#000000" }
}

export const useGetPlayerTestCategories = () => {
	const opClass = useOperationalClass()
	const sport = opClass?.name ?? "all"
	const categoriesTemplate = sport === "floorball" ? TEST_CATEGORIES_FLOORBALL_PLAYER : TEST_CATEGORIES_FOOTBALL_PLAYER
	// This used to have "{ ...e, ref: useRef(null) }", but that useRef was causing an exception. Not sure why it was used here.
	const categories = categoriesTemplate.map(e => ({ ...e }))

	return categories
}

/**
 * Has the user the given group role?
 * @param groupRole String
 * @returns {boolean}
 */
export const hasGroupRole = (groupRole) => {
	return getGroupRole() === groupRole
}

/**
 * Does the user have the product level?
 * @param productLevel
 * @returns {boolean}
 */
export const hasProductLevel = (productLevel) => {
	return getProductLevels().includes(productLevel)
}

// Wellbeing specific functions
/**
 * Is the operational class wellbeing?
 * @returns {boolean}
 */
export const isWellbeingOperational = () => {
	if (getOperationalClassName() === WELLBEING_OPERATIONAL_NAME) {
		return true
	}
	return false
}

export const useIsWellbeingOperational = () => {
	const opClassName = useOperationalClassName()
	if (opClassName === WELLBEING_OPERATIONAL_NAME) {
		return true
	}
	return false
}

/**
 * Does the user have the wellbeing process?
 * @returns {boolean}
 */
export const hasWellbeingProcess = () => {
	if (getProcessNames().includes(WELLBEING_PROCESS_NAME)) {
		return true
	}
	return false
}

/**
 * Has the user a wellbeing related group role?
 * 
 * Use `hasWellbeingCoachRole` or `hasWellbeingParticipantRole` for more fine-grained control.
 * @returns {boolean}
 */
export const hasWellbeingRole = () => {
	return getGroupRole() === WELLBEING_PARTICIPANT_ROLE_NAME || getGroupRole() === WELLBEING_COACH_ROLE_NAME
}

export const useHasWellbeingRole = () => {
	const { contextData } = useSelector(userSelector)
	return contextData.groupRole === WELLBEING_PARTICIPANT_ROLE_NAME || contextData.groupRole === WELLBEING_COACH_ROLE_NAME
}

export const getActiveRole = () => {
	const state = store.getState()
	const profileDataForCtx = _.get(state, "user.profileDataForCtx")
	if (!profileDataForCtx) {
		return null
	}
	return profileDataForCtx.activeMemberRole
}

export const useActiveRole = () => {
	const { profileDataForCtx } = useSelector(userSelector)
	if (!profileDataForCtx) {
		return null
	}
	return profileDataForCtx.activeMemberRole
}

export const isPlayerLike = () => {
	let curRole = getActiveRole()
	return !!curRole?.generalPerms?.includes("playerLike")
}

/** Returns whether or not is a player. Uses `useSelector` */
export const useIsPlayer = () => {
	const curRole = useActiveRole()
	return curRole?.name === SPORT_PLAYER_ROLE_NAME
}

export const usePlayerLike = () => {
	let curRole = useActiveRole()
	return !!curRole?.generalPerms?.includes("playerLike")
}

export const isCoachLike = () => {
	let curRole = getActiveRole()
	return !!curRole?.generalPerms?.includes("coachLike")
}

export const useCoachLike = () => {
	let curRole = useActiveRole()
	return !!curRole?.generalPerms?.includes("coachLike")
}

export const usePlayerLikeRoleNames = () => {
	const { data } = useGetTemplateRolesQuery()
	if (!data) {
		return []
	} else {
		return data.filter(o => o.generalPerms.includes("playerLike")).map(o => o.name)
	}
}

export const useCoachLikeRoleNames = () => {
	const { data } = useGetTemplateRolesQuery()
	if (!data) {
		return []
	} else {
		return data.filter(o => o.generalPerms.includes("coachLike")).map(o => o.name)
	}
}

/**
 * Has the user a wellbeing participant group role?
 * @returns {boolean}
 */
export const hasWellbeingParticipantRole = () => {
	return getGroupRole() === WELLBEING_PARTICIPANT_ROLE_NAME
}

/**
 * Has the user a wellbeing participant group role?
 * @returns {boolean}
 */
export const hasWellbeingCoachRole = () => {
	return getGroupRole() === WELLBEING_COACH_ROLE_NAME
}
