/**
 * Custom modal for `childSportsFollowUp` rule `profileMapObj` attribute value
 */


import { Modal, Table } from "react-bootstrap"
import _ from "lodash"
import { useTranslation } from "react-i18next"
import { UNIT_MAP } from "../../utils/rules"
import { useMemo, useState } from "react"
import { SortAlphaDown, SortAlphaUp, SortNumericDown, SortNumericUp } from "react-bootstrap-icons"

const getAlphaSort = (attrName) => ({
	"false": (a,b) => b[attrName]?.localeCompare(a[attrName]),
	"true": (a,b) => a[attrName]?.localeCompare(b[attrName]),
})

const getNumSort = (attrName) => ({
	"false": (a,b) => b[attrName] - a[attrName],
	"true": (a,b) => a[attrName] - b[attrName]
})

// Intended for sorting object entries
const sortDic = {
	name: getAlphaSort("name"),
	value: getNumSort("value"),
	done: getNumSort("done"),
	surveyAnswers: getNumSort("surveyAnswers"),
	exerciseEvents: getNumSort("exerciseEvents"),
}

const AGE_LIMIT = 11

const ChildSportsModal = ({ dataObj, curKey, handleClose }) => {

	const { t } = useTranslation()

	const [sortLabel, setSortLabel] = useState("name")
	const [asc, setAsc] = useState(true)

	const rule = _.get(dataObj, "rule")

	const processedData = useMemo(() => {
		if (!dataObj) {
			return []
		}

		// Include profile name in value object for easier access
		let processed = _.entries(dataObj.profileMap).map(([pId, obj]) => {
			return {
				id: pId,
				name: obj.name,
				value: obj.totalValidWeeks,
				done: obj.totalWeeksCriteriaMet,
				surveyAnswers: obj.totalExerciseSurveyN,
				exerciseEvents: obj.totalPersonalEventN,
			}
		})
		
		const sortFn = _.get(sortDic, [sortLabel, asc])
		if (sortFn) {
			processed.sort(sortFn)
		}
		return processed
	}, [sortLabel, asc, dataObj])

	const handleHeaderClick = (key) => {
		setSortLabel(key)
		if (sortLabel  ===  key) {
			setAsc(!asc)
		} else {
			setAsc(true)
		}
	}
	
	const infoString1 = "Laskettavan viikon ehtona on vähintään 3:na päivänä merkintää per vko ja tällä tavalla täytettyjä viikkoja vähintään 40 vkoa vuoden aikana."

	const infoString2 = `Merkintöjä on mahdollista tehdä kahdella tavalla:
	Henkilökohtainen tapahtuma (tyypit: "Omatoiminen pallollinen harjoittelu" ja "Muu liikunta ja harjoittelu"),
	Liikuntamäärän kysely (omatoiminen liikunta)
	`

	return (
		<Modal show={curKey==="childSportsFollowup"} onHide={handleClose} size="xl">
			<Modal.Header closeButton >
				<Modal.Title>{rule?.label}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<p>
					{t(infoString1)}
				</p>
				<p>
					{t(infoString2)}
				</p>
				<div className="mb-2 fw-bold">
					<div>{t("Alle {{n}}-vuotiaiden lukumäärä", { n: AGE_LIMIT })}: {_.keys(dataObj?.profileMap).length}</div>
					<div>{t("Ehdon toteuttaneiden lukumäärä")}: {dataObj?.value}</div>
				</div>
				<Table striped bordered>
					<thead>
						<tr>
							<th onClick={() => handleHeaderClick("name")} className="pointer">
								{t("Pelaaja")} {sortLabel === "name" ? (asc ? <SortAlphaDown /> : <SortAlphaUp />) : null}
							</th>
							<th onClick={() => handleHeaderClick("surveyAnswers")} className="pointer">
								{t("Arviokyselyn vastauksia yhteensä")} {sortLabel === "surveyAnswers" ? (asc ? <SortNumericDown /> : <SortNumericUp />) : null}
							</th>
							<th onClick={() => handleHeaderClick("exerciseEvents")} className="pointer">
								{t("Liikuntatapahtumamerkintöjä yhteensä")} {sortLabel === "exerciseEvents" ? (asc ? <SortNumericDown /> : <SortNumericUp />) : null}
							</th>
							<th onClick={() => handleHeaderClick("value")} className="pointer">
								{t("Valmiita viikkoja yhteensä")} {sortLabel === "value" ? (asc ? <SortNumericDown /> : <SortNumericUp />) : null}
							</th>
						</tr>
					</thead>
					<tbody>
						{processedData.map((obj) => {
							const { id, name, value, done, exerciseEvents, surveyAnswers } = obj
							return (
								<tr key={id}>
									<td>{name}</td>
									<td>{surveyAnswers}</td>
									<td>{exerciseEvents}</td>
									<td style={{ color: done ? "green" : "red"}}>{value} {`${UNIT_MAP(t)[dataObj.rule.unit] ?? dataObj.rule.unit ?? ""}`}</td>
								</tr>
							)
						})}
					</tbody>
				</Table>
			</Modal.Body>
		</Modal>
	)
}



export default ChildSportsModal