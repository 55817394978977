import { createSlice } from "@reduxjs/toolkit"
import _ from "lodash"

export const settingsSlice = createSlice({
	name: "settings",
	initialState: {
		selectedCalendar: "own",
		selectedCalendarName: "Oma kalenteri",
		calendarInitialized: false,
		calendarTabSetting: null,
		calendarEventTypeFilter: [],
		i18nextLng: "",
		selectedDate: null,
		// Personal client-side keyed list ordering
		ordering: { group: [] },
		// Expected values: "admin", "sport", "general"
		terminology: "general",
		// [{ type: <"group"|"child"|"own">, id: <ObjectId> }]
		calendarCombo: [],
		calendarScrollTop: undefined
	},
	reducers: {
		setCalendarScrollTop(state, { payload }) {
			state.calendarScrollTop = payload
		},
		setSelectedCalendar(state, { payload }) {
			state.selectedCalendar = payload
		},
		setSelectedCalendarName(state, { payload }) {
			state.selectedCalendarName = payload
		},
		setCalendarInitialized(state, { payload }) {
			state.calendarInitialized = payload
		},
		setCalendarTabSetting(state, { payload }) {
			state.calendarTabSetting = payload
		},
		setCalendarEventTypeFilter(state, { payload }) {
			state.calendarEventTypeFilter = payload
		},
		toggleCalendarEventTypeFilter(state, { payload }) {
			if ( !state.calendarEventTypeFilter ) {
				state.calendarEventTypeFilter = [payload]
			}
			else if ( state.calendarEventTypeFilter.includes(payload) ) {
				state.calendarEventTypeFilter = state.calendarEventTypeFilter.filter(t => t !== payload)
			} else {
				state.calendarEventTypeFilter = [...state.calendarEventTypeFilter, payload]
			}
		},
		setSelectedDate(state, {payload}) {
			state.selectedDate = payload
		},
		setOrdering(state,  {payload}) {
			state.ordering = payload
		},
		setTerminology(state, {payload}) {
			state.terminology = payload
		},
		setCalendarCombo(state, {payload}) {
			state.calendarCombo = payload
		}
	},
})

/**
 * Returns a newly ordered object array based on an external order array
 * 
 * @param {[Object]} targetArr          Array of objects to reorder
 * @param {[String]} orderArr           Array of identifiers that describes the order
 * @param {String} identifier           Optional identifier variable name
 */
export const applyOrdering = (targetArr, orderArr, identifier="_id") => {
	let indexObj = _.keyBy(targetArr, identifier)
	let ordered = []
	for (let id of orderArr) {
		let match = indexObj[id]
		if (match)
			ordered.push(match)
	}
	let unordered = _.differenceBy(targetArr, ordered, identifier)
	return ordered.concat(unordered)
}

export const { 
	setSelectedCalendar,
	setSelectedCalendarName,
	setCalendarInitialized,
	setCalendarTabSetting, 
	setCalendarEventTypeFilter,
	toggleCalendarEventTypeFilter,
	setSelectedDate,
	setOrdering,
	setTerminology,
	setCalendarCombo,
	setCalendarScrollTop
} = settingsSlice.actions
export const settingsSelector = (state) => state.settings