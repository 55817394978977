/**
 * Generic modal implementation when only profile name and a single 
 * value needs to be displayed in a table, ie. `profileMap` attribute value.
 */


import { Modal, Spinner, Table } from "react-bootstrap"
import _ from "lodash"
import { useTranslation } from "react-i18next"
import { useGetRootGroupClubSupportPlayersQuery } from "@api/group-api"
import { useNavigate, useParams } from "react-router-dom"
import { UNIT_MAP } from "../../utils/rules"
import { useMemo, useState } from "react"
import { SortAlphaDown, SortAlphaUp, SortNumericDown, SortNumericUp } from "react-bootstrap-icons"
import toast from "react-hot-toast"
import { userSelector } from "@redux/user"
import { useSelector } from "react-redux"
import { getIsCoach } from "@utils/customization/customization"

// Intended for sorting object entries
const sortDic = {
	name: {
		"false": ([,a],[,b]) => b.name?.localeCompare(a.name),
		"true": ([,a],[,b]) => a.name?.localeCompare(b.name),
	},
	value: {
		"false": ([,a],[,b]) => b.value - a.value,
		"true": ([,a],[,b]) => a.value - b.value
	}
}

const validKeys = [
	"academyPlayersTotalMinutes",
	"talentPlayersTotalMinutes",
	"talentPlayersTotalMorningMinutes",
	"academyPlayersPlayMinutesTotal",
	"talentPlayersPlayMinutesTotal",
	"playersAbsenceWeeks"
]

const ProfileValueModal = ({ dataObj, curKey, handleClose }) => {
	const { contextData } = useSelector(userSelector)
	const navigate = useNavigate()
	const { t } = useTranslation()
	const params = useParams()
	const { rootGroupId } = params

	const isCoach = getIsCoach()

	const navigateToExercisePage = (profileId) => {
		if (contextData.rootGroup !== rootGroupId) {
			return toast.error(t("Harjoittelu-sivulle siirtyminen estetty, koska roolivalitsimen asiakkuus ei täsmää sivun asiakkuuden kanssa"), { id: "access-blocked-rg-context"})
		}
		if (!isCoach) {
			return toast.error(t("Harjoittelu-sivulle siirtyminen estetty, koska käyttäjä ei ole asiakkuudessa valmentaja tai valmennuspäällikkö"), { id: "access-blocked-role" })
		}
		navigate(`/charts/exercise?profileId=${profileId}`)
	}

	const rowClickHandlers = {
		"academyPlayersTotalMinutes": navigateToExercisePage,
		"talentPlayersTotalMinutes": navigateToExercisePage,
		"talentPlayersTotalMorningMinutes": navigateToExercisePage,
		"academyPlayersPlayMinutesTotal": navigateToExercisePage,
		"talentPlayersPlayMinutesTotal": navigateToExercisePage,
	}

	const titleDictionary = {
		"playersAbsenceWeeks": [null,t("Poissaoloviikot (1 poissaoloviikko = 7 poissaolopäivää)")]
	}

	const handleRowClick = rowClickHandlers[curKey]

	const { data: playersData } = useGetRootGroupClubSupportPlayersQuery({ id: rootGroupId })

	const [sortLabel, setSortLabel] = useState("name")
	const [asc, setAsc] = useState(true)

	const getProfileName = pId => {
		const academyPlayers = _.get(playersData, "academyPlayers.players") ?? []
		const talentPlayers = _.get(playersData, "talentPlayers.players") ?? []
		const allPlayers = [...academyPlayers, ...talentPlayers]
		const match = allPlayers.find(o => o._id === pId)
		if (!match) {
			return pId
		}
		return `${match.lastname} ${match.firstname}`
	}

	const rule = _.get(dataObj, "rule")

	const playerEntries = useMemo(() => {
		if (!dataObj || !playersData) {
			return []
		}

		// Include profile name in value object for easier access
		let processed = _.mapValues(dataObj.profileMap, (value, key) => ({ value: value, name: getProfileName(key), profileId: key }))
		let entries = _.entries(processed)
		
		const sortFn = sortDic[sortLabel][asc]
		if (sortFn) {
			entries.sort(sortFn)
		}
		return entries
	}, [sortLabel, asc, dataObj, playersData])

	const handleHeaderClick = (key) => {
		setSortLabel(key)
		if (sortLabel  ===  key) {
			setAsc(!asc)
		} else {
			setAsc(true)
		}
	}
	

	return (
		<Modal show={validKeys.includes(curKey)} onHide={handleClose} size="lg">
			<Modal.Header closeButton >
				<Modal.Title>{rule?.label}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Table hover={!!handleRowClick} striped bordered>
					<thead>
						<tr>
							<th onClick={() => handleHeaderClick("name")} className="pointer">
								{titleDictionary[curKey]?.[0] || t("Pelaaja")} {sortLabel === "name" ? (asc ? <SortAlphaDown /> : <SortAlphaUp />) : null}
							</th>
							<th onClick={() => handleHeaderClick("value")} className="pointer">
								{titleDictionary[curKey]?.[1] || t("Arvo")} {sortLabel === "value" ? (asc ? <SortNumericDown /> : <SortNumericUp />) : null}
							</th>
						</tr>
					</thead>
					<tbody>
						{!playersData ? <Spinner animation="border" size="sm" /> : playerEntries.map(([pId, valueObj]) => {
							return (
								<tr 
									key={pId} 
									onClick={handleRowClick ? () => handleRowClick(valueObj.profileId) : null}
									className={handleRowClick ? "pointer" : undefined}
								>
									<td>{valueObj.name}</td>
									<td>{valueObj.value} {`${UNIT_MAP(t)[dataObj.rule.unit] ?? dataObj.rule.unit}`}</td>
								</tr>
							)
						})}
					</tbody>
				</Table>
			</Modal.Body>
		</Modal>
	)
}



export default ProfileValueModal